/* line 15, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing__ownersquote-container {
	display: flex;
	margin-bottom: 100px;
	text-align: center;
}

/* line 20, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing__ownersquote-container .icon {
	align-self: flex-end;
	font-size: 5em;
	color: rgb(var(--color-global-widget2));
}

/* line 25, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing__ownersquote-container .icon:first-child {
	transform: rotate(180deg);
	align-self: flex-start;
}

@media only screen and (max-width: 47.999em) {
	/* line 20, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-container .icon {
		display: none;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 15, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-container {
		margin-bottom: 60px;
	}
}

/* line 40, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing__ownersquote-title {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	margin: 100px 0 40px;
	color: rgb(var(--color-global-primary));
	text-align: center;
}

@media only screen and (max-width: 47.999em) {
	/* line 40, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-title {
		font-size: 1.6245em;
		line-height: 1.1525;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 40, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-title {
		font-size: 1.998em;
		line-height: 1.25;
	}
}

@media only screen and (min-width: 80em) {
	/* line 40, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-title {
		font-size: 2.25em;
		line-height: 1.25;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 40, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-title {
		margin-top: 60px;
	}
}

/* line 51, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing__ownersquote-message {
	margin: 0 40px;
	text-align: left;
	width: 100%;
}

/* line 56, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing__ownersquote-message-text {
	border-right: 1px solid rgb(var(--color-global-border));
	border-left: 1px solid rgb(var(--color-global-border));
	padding: 0 40px;
	margin: 20px 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 56, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-message-text {
		border: 0;
		padding: 0 20px;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 51, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
	.listing__ownersquote-message {
		margin: 0;
	}
}

/* line 73, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing__attribution {
	border-top: 1px solid rgb(var(--color-global-border));
	padding-top: 10px;
	margin-bottom: 20px;
}

/* line 82, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing-info__property-ids {
	margin: 20px;
}

/* line 86, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing-info__property-item {
	color: rgb(var(--color-global-positive));
}

/* line 89, scss/80-themes/Saunders/70-modules/customlistinginfo/module.scss */
.listing-info__property-item:first-child {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-info__property-item:first-child {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-info__property-item:first-child {
		margin-right: 0;
	}
}

/*# sourceMappingURL=../../../../../true */